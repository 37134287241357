import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { orange, primary } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${orange.dark};
  padding: 22px 0;

  h1 {
    color: ${orange.dark};

    .title {
      color: ${primary[500]};
    }
  }

  .rates {
    color: ${primary[500]};
  }

  .custom-rates {
    color: ${orange.dark};
  }

  .bg-white {
    background-color: ${white};
    padding: 34px;
    border-radius: 16px;
    height: auto;

    @media ${device.tablet} {
      height: 551px;
      padding: 39px;
    }

    @media ${device.desktopLG} {
      height: 676px;
      padding: 39px;
    }

    @media ${device.desktopXL} {
      height: 668px;
      padding: 39px;
    }

    @media ${device.desktopXXXL} {
      height: 752px;
      padding: 55px;
    }
  }

  .bg-form {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    height: auto;

    @media ${device.tablet} {
      padding: 20px 24px 20px 24px;
    }

    @media ${device.desktopLG} {
      padding: 43px 34px;
    }

    @media ${device.desktopXL} {
      padding: 47px 34px;
    }

    @media ${device.desktopXXXL} {
      padding: 89px 34px;
    }
  }

  input{
    border-radius: 8px;
    width: 100%;
    height: 40px;
    background: #F5F6FA;
    border: none;
    padding: 16px;
    color: #161616;
    font-size: 14px;
    line-height: 17px;

    &::placeholder{
      font-size: 14px;
      line-height: 17px;
      color: #B6B7BB;
      font-weight: 600;
    }

    &:focus{
      outline: none;
    }

  }
  @media ${device.desktopLG} {
    .form-label-check{
      display: flex;
      align-items: center;
      padding-left: 0;

      a{
        margin: 0 3px;
      }

      &::before{
        position: static;
        margin-right: 10px;
      }
    }

  }

  .radio__container {
    display: flex;
    gap: 24px;

    label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: ${grayscale[400]};
    }
  }
`

export const Rates = styled.div`
  border-top: 1px dashed ${grayscale[200]};
`

export const Button = styled.button`
  border-radius: 8px;
  background: #ff7a00;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border: none;
  font-weight: 600;

  &:focus{
    outline: none;
  }

  &:disabled {
    background: ${grayscale['200']};
    color: ${grayscale['300']} !important;
  }
`

import React, { useState, ChangeEvent } from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import { Radio } from '@interco/inter-ui/components/Radio'

import { useForm, UseFormMethods } from 'react-hook-form'
import axios from 'axios'
import * as URLS from 'src/config/api/Urls'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { sendCDPFormData } from 'src/shared/helpers'
import ModalForm from '../../_components/_modal/_index'
import { Modal } from 'src/components/Modal'

import AcceptTerms from '../../_components/AcceptTerms/_index'
import * as S from './style'
interface IFormValues {
  nome: string;
  CpfCnpj: string;
  telefone: string;
  email: string;
  conteudo01: string;
  conteudo02: string;
  conteudo03: string;
}

const formatStringAsCurrency = (currency: string) => {
  let aux = currency.replace(/\D/g, '')
  aux = aux.replaceAll('R$', '')
  aux = (parseFloat(aux) / 100).toFixed(2) + ''
  aux = aux.replace('.', ',')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4.$5.$6,')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4.$5,')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4,')
  aux = aux.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
  aux = aux.replace(/(\d)(\d{3}),/g, '$1.$2,')
  aux = `R$ ${aux}`
  return aux
}

function formatNumber (telefone: string): string {
  const cleanNumber = telefone.replace(/[()\s-]/g, '')
  return '55' + cleanNumber
}

const Hero = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ accept, setAccept ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ modal, setModal ] = useState(false)
  const [ name, setName ] = useState('')
  const [ state, setState ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ clienteMEI, setClienteMEI ] = useState<string>('')

  const formClean = () => {
    setName('')
    setEmail('')
    setState('')
  }

  const summit = async (data: IFormValues) => {
    setLoading(true)
    const formData = {
      ...data,
      CpfCnpj: data.CpfCnpj.replace(/\D/g, ''),
      telefone: formatNumber(data.telefone),
      conteudo02: (data.conteudo02).replaceAll(/[/().R$\- ]/g, '').replace(/,.*/, ''),
      campanha: 'Inter Pag',
      aceite: accept,
    }
      try {
        await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ])
        sendCDPFormData({ formName: '', cpf: '', email: data.email })
        setError(false)
        setSuccess(true)
        setLoading(false)
        setModal(true)
        formClean()
        sendDatalayerEvent({
          section: 'dobra_1',
          section_name: 'Inter Pag: A solução completa para suas vendas',
          element_action: 'submit',
          element_name: 'Solicitar maquininha',
          step: 'success',
        })
      } catch (error) {
        setSuccess(false)
        setError(true)
        setLoading(false)
        setModal(true)
        formClean()
      }
  }

  const domReady = useDomReady()
  const modalForm = domReady && (
    <Modal isModalOpen={modal} setIsModalOpen={setModal} locationToRender={document.body}>
      <ModalForm closeModal={setModal} setError={setError} setSuccess={setSuccess} error={error} sent={success} />
    </Modal>
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 2) {
      setState(e.target.value)
    }
  }

  return (
    <S.Section id='hero' className='d-flex align-items-center'>
      {modalForm}
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5 offset-xl-1'>
            <div className='bg-white'>
              <div className='w-100 d-flex justify-content-center'>
                { width < WIDTH_MD &&
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-hero-360/image.webp'
                    altDescription='Imagem da maquininha Inter Pag'
                    arrayNumbers={[ 264, 234, 304, 329, 329 ]}
                  />
                }
                { width >= WIDTH_MD &&
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-pag-hero-1440/image.webp'
                    altDescription='Imagem da maquininha Inter Pag'
                    arrayNumbers={[ 264, 269, 376, 376, 469 ]}
                  />
                }
              </div>
              <h1 className='fs-28 fs-lg-40 lh-33 lh-lg-44 fw-500 font-citrina mt-3'>
                <span className='title d-block'>Inter Pag:</span>
                <span className='fw-400'>A solução completa para suas vendas</span>
              </h1>
              <S.Rates className='pt-3 mt-md-4 mt-lg-4'>
                <p className='fs-16 fs-md-20 fs-lg-24 lh-20 lh-md-24 lh-lg-28 fw-600 rates mt-md-2 mt-lg-2 font-citrina'>
                  <span className='d-md-block d-lg-inline'>Taxas personalizadas</span> a partir de:
                </p>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='mr-3'><OrangeDsIcon icon='card' size='MD' color='#72370E' /></div>
                    <div>
                      <p className='fs-16 fs-lg-24 lh-19 lh-lg-28 mb-0 fw-600 custom-rates font-citrina'>
                        <span className='d-block'>0,75%</span><span className='fw-400'>no débito</span>
                      </p>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='mr-3'><OrangeDsIcon icon='card' size='MD' color='#72370E' /></div>
                    <div>
                      <p className='fs-16 fs-lg-24 lh-19 lh-lg-28 mb-0 fw-600 custom-rates font-citrina'>
                        <span className='d-block'>1,83%</span><span className='fw-400'>no crédito</span>
                      </p>
                    </div>
                  </div>
                </div>
              </S.Rates>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-5 mt-3 mt-md-0'>
            <div className='bg-form'>
              <form onSubmit={handleSubmit(summit)}>
                <div className='row'>
                  <div className='col-12 mb-3 mb-xl-2 mb-lg-3'>
                    <p className='fs-14 fs-lg-20 lh-16 lh-lg-24 fw-600 text-grayscale--500 text-center'>Preencha o formulário para receber contato de um de nossos especialistas</p>
                    <label className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>Nome</label>
                    <input
                      type='text'
                      name='nome'
                      id='nome'
                      value={name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                      placeholder='Digite o seu nome completo'
                      ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    />
                    {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                  </div>
                  <div className={`col-12 col-md-6 col-lg-12 mb-3 position-relative ${errors.CpfCnpj && 'input-error'}`}>
                    <label htmlFor='CpfCnpj' className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>CNPJ</label>
                    <input
                      className='w-100'
                      ref={register({
                        required: 'CNPJ obrigatório',
                        validate: {
                          CpfCnpj: (value: string) => Validations.cpforcnpj(value) || 'CNPJ inválido',
                        },
                      })}
                      name='CpfCnpj'
                      id='CpfCnpj'
                      type='text'
                      placeholder='Digite o CNPJ da sua empresa'
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCNPJ(event.currentTarget.value))}
                    />
                    { errors.CpfCnpj && <p className='fs-12 text-red--base pt-1 mb-0 text-right '>{errors.CpfCnpj.message}</p> }
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 mb-3'>
                    <label htmlFor='telefone' className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>Celular</label>
                    <input
                      ref={register({
                        required: 'Digite o seu número de celular',
                        pattern: {
                          value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                          message: 'Telefone inválido',
                        },
                      })}
                      name='telefone'
                      id='telefone'
                      type='text'
                      placeholder='Digite seu celular'
                      maxLength={15}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('telefone', Masks.MaskPHONE(event.currentTarget.value))}
                    />
                    {errors.telefone && <p className='fs-12 text-red--base mb-0 text-right'>{errors.telefone.message}</p>}
                  </div>
                  <div className='col-12 col-md-6 mb-3 mb-xl-2 mb-lg-3'>
                    <label htmlFor='conteudo03' className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>UF</label>
                    <input
                      type='text'
                      name='conteudo03'
                      id='conteudo03'
                      value={state}
                      placeholder='Digite a UF'
                      onChange={handleChange}
                      ref={register({
                      required: 'Por favor, digite a UF',
                      validate: {
                        isTwoChars: (value: string) => value.length === 2 || 'A UF deve ter exatamente 2 caracteres',
                      },
                    })}
                    />
                    {errors.conteudo03 && <p className='fs-12 text-red--base mb-0 text-right'>{errors.conteudo03.message}</p>}
                  </div>
                  <div className='col-12 col-md-6 col-lg-12 mb-3'>
                    <label className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>E-mail</label>
                    <input
                      name='email'
                      id='email'
                      type='text'
                      value={email}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event?.target.value)}
                      placeholder='Digite o seu e-mail'
                      ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    />
                    {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                  </div>
                  <div className='col-12 mb-2'>
                    <label className='fs-14 lh-17 fw-400 text-grayscale--400 d-block'>Expectativa de faturamento mensal</label>
                    <input
                      name='conteudo02'
                      id='faturamento'
                      type='text'
                      placeholder='Ex.: R$ 20.000,00'
                      ref={register({
                        required: 'Digite um Faturamento válido' || true,
                        pattern: {
                          value: /^(R\$)?\s?(\d{1,3}(\.\d{3})*|\d+)(,\d{2})?$/,
                          message: 'Digite um faturamento válido',
                        },
                      })}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const formatedValue = formatStringAsCurrency(event.currentTarget.value)
                        setValue('conteudo02', formatedValue, { shouldValidate: true })
                      }}
                    />
                    {errors.conteudo01 && <p className='fs-12 text-red--base mb-0 text-right'>{errors.conteudo01.message}</p>}
                  </div>
                  <div className='col-12 mb-2 d-flex align-items-center'>
                    <label className='fs-14 lh-17 fw-400 text-grayscale--400 d-block mb-0 mr-4'>É MEI?</label>
                    <div className='radio__container'>
                      <Radio
                        id='label-1'
                        value='Sim'
                        name='label'
                        label='Sim'
                        size={24}
                        checked={clienteMEI === 'Sim'}
                        onChange={() => setClienteMEI("Sim")}
                      />

                      <Radio
                        id='label-2'
                        value='Nao'
                        name='label'
                        label='Não'
                        size={24}
                        checked={clienteMEI === 'Nao'}
                        onChange={() => setClienteMEI("Nao")}
                      />
                    </div>
                    <input
                      type='hidden'
                      name='conteudo01'
                      id='conteudo01'
                      value={clienteMEI}
                      ref={register({
                       required: 'Por favor, selecione uma opção!',
                     })}
                    />
                  </div>
                  <div className='col-12'>
                    {errors.conteudo01 && <p className='fs-12 text-red--base mb-0 text-right'>{errors.conteudo01.message}</p>}
                  </div>
                  <div className='col-12 mt-2'>
                    <AcceptTerms
                      accept={accept}
                      setAccept={setAccept}
                    />
                  </div>
                  <div className='col-12 mt-2 mt-md-0 mt-lg-3'>
                    <S.Button type='submit' disabled={!accept || loading}>{loading ? 'Enviando...' : 'Solicitar maquininha'}</S.Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
